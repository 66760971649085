.RouteCard {
  position: relative;
  width: 20vw;
  height: 30vw;
  cursor: pointer;
  flex: 0 0 auto; /* лучшая вещь */
  border-radius: 1.2vw;
}

.RouteCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2vw;
  background-color: rgba(0, 0, 0, 0.2); /* Черный цвет с 50% прозрачностью */
  z-index: 0;
}

.RouteCard .titles {
  position: absolute;
  top: 5%;
  left: 7%;
  width: 70%;
  padding: 0;
  display: flex;
  text-align: left;
  flex-flow: wrap;
  flex-direction: column;
  gap: 0.2vw;
  height: auto;
}

.RouteCard .first_title {
  position: relative;
  font-family: Rubik-B;
  color: aliceblue;
  font-size: 1.8vw;
}

.RouteCard .sec_title {
  position: relative;
  font-family: Rubik;
  color: aliceblue;
  font-size: 1.2vw;
}

.RouteCard .niz_title {
  position: absolute;
  font-family: Rubik;
  color: aliceblue;
  bottom: 7%;
  left: 7%;
  font-size: 1.3vw;
  width: 10vw;
  display: flex;
  gap: 0.5vw;
  flex-flow: wrap;
}

.RouteCard .niz_title div {
  width: 100%;
  display: flex;
  gap: 0.5vw;
}

.RouteCard .Btn {
  position: absolute;
  bottom: 7%;
  right: 7%;
}

.RouteCard .niz_title img {
  width: 12%;
}

@media (max-width: 450px) {
  .RouteCard {
    width: 60vw;
    height: 80vw;
    border-radius: 4vw;
    overflow: hidden;
  }
  
  .RouteCard .niz_title img {
    width: 20%;
  }
  
  .RouteCard .first_title {
    font-size: 4vw;
  }
  
  .RouteCard .sec_title {
    font-size: 3vw;
  }
  
  .RouteCard .niz_title {
    font-size: 4vw;
    width: 20vw;
  }
}