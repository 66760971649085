* {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik-Medium"), url(../fonts/Rubik-Medium.ttf);
}
@font-face {
  font-family: "Rubik-EB";
  src: local("Rubik-EB"), url(../fonts/Rubik-ExtraBold.ttf);
}
@font-face {
  font-family: "Rubik-B";
  src: local("Rubik-B"), url(../fonts/Rubik-Bold.ttf);
}
@font-face {
  font-family: "Rubik-Re";
  src: local("Rubik-Re"), url(../fonts/Rubik-Regular.ttf);
}

h2 {
  padding-top: 4vw;
  padding-bottom: 1.5vw;
  color: #1e444b;
  font-family: "Rubik-B";
  font-size: 2.3vw;
}

h3 {
  color: #1e444b;
  font-family: "Rubik-B";
  font-size: 1.6vw;
  padding: 0;
  margin-bottom: 1vw;
}

p {
  color: #1e444b;
  font-family: "Rubik-Re";
  font-size: 1.3vw;
  margin-bottom: 2vw;
}

a {
  font-family: "Rubik-Re";
  font-size: 1.3vw;
  text-decoration: none;
}

/* Home */

.main {
  margin: 0 5vw;
  justify-content: center;
}

.route_cards {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 15px;
  width: 100%;
  z-index: 1;
  margin: 0 -5vw;
  margin-bottom: 3vw;
  padding: 0 5vw;
}

.route_cards::-webkit-scrollbar {
  display: none;
}

/* последняя плашка на блоке оопт */

.last .first_title {
  position: absolute;
  top: 18%;
  left: 2%;
  font-family: Rubik-B;
  color: aliceblue;
  width: 70%;
  font-size: 1.8vw;
}

.oopt_cards {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.last {
  position: relative;
  flex: 0 0 auto; /* лучшая вещь */
  height: 11vw;
  width: 100%;
  border-radius: 1.2vw;
}

.last .Btn {
  position: absolute;
  bottom: 15%;
  left: 2%;
}

/* Map */

.search_wrap {
  position: absolute;
  top: 7vw;
  left: 2vw;
}

.search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 25vw;
  background-color: #f5f5f5;
  border-radius: 1rem;
  box-shadow: #000000 1px 0 20px;
  padding: 1rem;
  flex-wrap: wrap;
}

.search_item {
  border-radius: 0.5rem;
  font-size: 1vw;
  font-family: Rubik-Re;
  align-content: center;
  padding: 0.5vw;
  margin: 0.2vw;
}

.sit1 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 18vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #56565690;
}

.sit2 {
  white-space: nowrap;
  overflow: hidden;
}

.sit3 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 3vw;
  font-family: Rubik;
}

.sit4 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 9vw;
  font-family: Rubik;
}

.sit5 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 13vw;
  font-family: Rubik;
}

.sit6 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 6vw;
  font-family: Rubik;
}

.sit7 {
  height: 2vw;
  background-color: #ffffff;
  flex-basis: 15vw;
  font-family: Rubik;
}

.plmi_wrap {
  position: absolute;
  top: 20vw;
  right: 3vw;
}

.plmi {
  position: relative;
  flex-direction: column;
}

.plm {
  width: 1.5vw;
  padding: 35%;
}

.plmiitem {
  box-sizing: border-box;
  width: 5vw;
  height: 5vw;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin: 1vw;
  box-shadow: #000000 1px 0 15px;
}

/* Route */

.rtinfo_flex {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 5vw;
  text-align: justify;
  width: 90vw;
}

.rtinfo1 {
  color: #1e444b;
  font-family: Rubik-EB;
  font-size: 1.7vw;
}

.rtinfo2 {
  color: #1e444b;
  font-family: Rubik-Re;
  font-size: 1.3vw;
}

.rtinfo3 {
  position: relative;
  color: #1e444b;
  font-family: Rubik-Re;
  font-size: 1.3vw;
  display: flex;
}

.oopt_rules {
  border-radius: 0.5rem;
  font-size: 1vw;
  font-family: Rubik;
  padding: 0.5vw;
  margin: 0.2vw;
  margin-bottom: 2vw;
  padding: 1vw;
  color: #1e444b;
  font-size: 1.3vw;
  border: #1e444b 3px solid;
  background-color: rgba(139, 69, 19, 0);
}

.inf3it {
  color: #1e444b;
  font-family: Rubik-Re;
  font-size: 1vw;
}

.paint {
  fill: none;
  stroke: black;
  stroke-width: 3px;
  cursor: pointer;
  pointer-events: all;
}

/* About */
.about_us_block {
  margin: 0 5vw;
  margin-top: 7vw;
  margin-bottom: 3vw;
  justify-content: center;
}

.social {
  display: flex;
  gap: 2vw;
  margin-bottom: 2vw;
}

.social img {
  width: 2vw;
  margin-top: -0.1vw;
}

.social p {
  margin: 0;
}

.social a {
  text-decoration: none;
}

.soc {
  display: flex;
  gap: 1vw;
}

/* Oopt */
.OoptSearchBar {
  width: 100vw;
  height: 23vw;
  background-color: #1e444b;
  flex: 0 0 auto; /* лучшая вещь */
  align-items: center;
}

.OoptSearchBar h1 {
  font-size: 3vw;
  margin-bottom: 3vw;
  font-family: "Rubik-B";
}

.oopt_wrap {
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 8vw;
}

.oopt_flex {
  display: flex;
  flex-direction: column;
  font-family: Rubik-Re;
  color: #f7f7f7;
  font-size: 1vw;
  justify-content: space-between;
  flex-wrap: wrap;
}

.oopt_info {
  border-radius: 0.5rem;
  font-size: 1vw;
  font-family: Rubik;
  align-content: center;
  padding: 0.5vw 2vw;
  box-sizing: content-box;
  background-color: #ffffff;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.input-group {
  display: flex;
  gap: 5vw;
  flex-wrap: wrap;
  width: 90vw;
}

.selectItem {
  width: 16vw;
}

@media (max-width: 450px) {
  h2 {
    font-size: 5vw;
    padding-bottom: 3vw;
    padding-top: 8vw;
  }

  h3 {
    font-size: 4.5vw;
    padding-top: 3vw;
  }

  p {
    font-size: 3.8vw;
    padding: 2vw 0;
  }

  a {
    font-size: 3vw;
  }

  .route_cards {
    margin-bottom: 5vw;
  }

  .oopt_cards {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    scroll-snap-type: x mandatory;
    gap: 15px;
    margin: 0 -5vw;
    padding: 0 5vw;
    margin-bottom: 8vw;
    width: 100%;
  }

  .oopt_cards::-webkit-scrollbar {
    display: none;
  }

  .last .first_title {
    position: absolute;
    top: 5%;
    left: 7%;
    font-family: Rubik-B;
    color: aliceblue;
    font-size: 4vw;
  }

  .last {
    position: relative;
    width: 60vw;
    height: 80vw;
    border-radius: 4vw;
    cursor: pointer;
    flex: 0 0 auto; /* лучшая вещь */
  }

  .last .Btn {
    position: absolute;
    bottom: 7%;
    left: 7%;
  }

  /* Oopt */
  .OoptSearchBar {
    height: auto;
    margin-bottom: 5vw;
  }

  .OoptSearchBar h1 {
    font-size: 5vw;
  }

  .oopt_wrap {
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 25vw;
  }

  .oopt_flex {
    font-size: 3vw;
  }

  .oopt_info {
    height: 10vw;
    font-size: 3vw;
    margin-bottom: 10vw;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 5vw;
    flex-wrap: wrap;
    width: 90vw;
  }

  .selectItem {
    width: 90vw;
  }

  /* About us */

  .about_us_block {
    margin: 0 5vw;
    margin-top: 18vw;
    margin-bottom: 3vw;
    justify-content: center;
  }

  .about_us_block .social {
    margin-top: 5vw;
    flex-direction: column;
  }
  
  .about_us_block .soc {
    flex-direction: column;
    align-items: center;
    gap: 1vw;
  }
  
  .social img {
    width: 5vw;
    margin-top: -0.1vw;
  }
  
  .soc {
    display: flex;
    gap: 1vw;
  }
}

.map-page {
  position: relative;
  overflow: hidden;
}
