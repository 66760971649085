.BackBtn {
  position: absolute;
  top: 8vw;
  left: 5vw;
  color: #fff;
}

.BackBtn:hover {
  cursor: pointer;
}

.BackBtn p {
  color: aliceblue;
}

@media (max-width: 450px) {
  .BackBtn {
    top: 20vw;
  }
}