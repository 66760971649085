.OoptCard {
  position: relative;
  overflow: hidden;
  height: 11vw;
  width: 100%;
  cursor: pointer;
  flex: 0 0 auto;
  border-radius: 1.2vw;
}

.OoptCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2vw;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.OoptCard .titles {
  position: absolute;
  top: 18%;
  left: 2%;
  padding: 0;
  display: flex;
  text-align: left;
  flex-flow: column;
}

.OoptCard .first_title {
  font-family: Rubik-B;
  color: aliceblue;
  font-size: 1.8vw;
  position: relative;
}

.OoptCard .sec_title {
  position: relative;
  font-size: 1.2vw;
  font-family: Rubik;
  color: aliceblue;
}

.OoptCard .niz_title {
  position: absolute;
  font-family: Rubik;
  color: aliceblue;
  bottom: 15%;
  left: 2%;
  font-size: 1.3vw;
  width: 10em;
}

.OoptCard .Btn {
  position: absolute;
  bottom: 15%;
  right: 2%;
}

@media (min-width: 451px) {
  .OoptCard .first_title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 450px) {
  .OoptCard {
    position: relative;
    width: 60vw;
    height: 80vw;
    border-radius: 4vw;
    cursor: pointer;
    flex: 0 0 auto; /* лучшая вещь */
  }

  .OoptCard::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.2vw;
    background-color: rgba(0, 0, 0, 0.2); /* Черный цвет с 50% прозрачностью */
    z-index: 0;
  }

  .OoptCard .titles {
    position: absolute;
    top: 5%;
    left: 7%;
    width: 70%;
    padding: 0;
    display: flex;
    text-align: left;
    flex-flow: wrap;
    gap: 0.2vw;
    height: auto;
  }
  
  .OoptCard .first_title {
    position: relative;
    overflow: visible;
    width: 70%;
    font-family: Rubik-B;
    color: aliceblue;
    font-size: 4vw;
  }
  
  .OoptCard .sec_title {
    position: relative;
    font-family: Rubik;
    color: aliceblue;
    font-size: 3vw;
  }
  
  .OoptCard .niz_title {
    position: absolute;
    font-family: Rubik;
    color: aliceblue;
    bottom: 20%;
    left: 7%;
    display: flex;
    gap: 1vw;
    flex-flow: wrap;
    font-size: 3.8vw;
    /* width: 30vw; */
  }
  
  .OoptCard .Btn {
    position: absolute;
    bottom: 7%;
    left: 7%;
    right: auto;
  }

  .OoptCard .niz_title img {
    width: 12%;
  }
}