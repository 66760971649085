.MapBlock {
  position: relative;
}

.MapBlock img {
  width: 100%;
}

.MapBlock .Btn {
  position: absolute;
  left: 3%;
  bottom: 7%;
}