.AboutNsd {
  position: relative;
  font-size: 30px;
}

.first_block {
  display: flex;
  position: absolute;
  top: 10%;
  justify-content: space-between;
  left: 10%;
  right: 10%;
  height: 2.5vw;
}

.AboutNsd .Btn {
  padding-bottom: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: aliceblue;
  color: #1e444b;
  font-size: 1.3vw;
}

.route {
  position: absolute;
  top: 40%;
  left: 6%;
  width: 86vw;
  height: 12.7em;
}

.numbers {
  display: flex;
  font-family: Rubik-B;
  font-size: 15vw;
  position: absolute;
  top: 30%;
  left: 8%;
  opacity: 20%;
  color: aliceblue;
  justify-content: space-between;
  width: 80%;
  height: 50%;
}

.titles {
  display: flex;
  font-family: Rubik;
  font-size: 1.5vw;
  position: absolute;
  top: 40%;
  left: 8%;
  color: aliceblue;
  justify-content: space-between;
  width: 80%;
  height: 30%;
  text-align: center;
}

.b {
  align-self: flex-start;
  padding-left: 5%;
}

.d {
  align-self: flex-start;
}

.a {
  align-self: flex-end;
  padding-left: 2%;
}

.c {
  align-self: flex-end;
}

.e {
  align-self: flex-end;
}

.bb {
  align-self: flex-start;
  padding-left: 8%;
}

.dd {
  padding-left: 11%;
  align-self: flex-start;
}

.aa {
  align-self: flex-end;
  padding-left: 2%;
}

.cc {
  align-self: flex-end;
  padding-left: 3em;
}

.ee {
  align-self: flex-end;
  padding-left: 4%;
}

.foot_sec_chis {
  position: absolute;
  display: flex;
  color: aliceblue;
  top: 83.5%;
  left: 40%;
  justify-content: space-between;
  font-family: Rubik-B;
  width: 35%;
  font-size: 4.8vw;
}

.card {
  position: absolute;
  top: 15vw;
  left: 40vw;
  font-size: 30%;
  background-color: aliceblue;
  width: 10vw;
  color: #1e444b;
  font-family: Rubik-Re;
  font-size: 0.7vw;
  border-radius: 2em;
  padding: 2em;
  transform: rotate(-20deg);
}

.score {
  position: absolute;
  display: flex;
  bottom: 10%;
  left: 6%;
  justify-content: space-between;
  flex-flow: wrap;
  width: 85vw;
  align-items: flex-end;
}

.score h2, .score h3 {
  color: aliceblue;
  padding: 0;
  margin: 0;
  margin-bottom: 1vw;
}

.score_num {
  display: flex;
  gap: 5vw;
  align-items: end;
}

.block_num {
  display: flex;
  gap: 1vw;
  flex-flow: wrap;
  align-items: flex-end;
}

.block_num h2 {
  font-size: 5vw;
  margin-bottom: 0;
}

.block_num h3 {
  font: "Rubik";
}

@media (max-width: 450px) {
  .AboutNsd .first_block img {
    display: none !important;
  }

  .AboutNsd .first_block {
    left: 6vw;
    right: 6vw;
    height: auto;
  }

  .AboutNsd .first_block .Btn {
    padding-bottom: 1.5vw;
    padding-left: 3vw;
    padding-right: 3vw;
    font-size: 2.5vw ;
  }
  .AboutNsd .route, .numbers, .titles, .card {
    display: none;
  }
  .AboutNsd .score {
    top: 20vw;
    position: absolute;
    display: flex;
    justify-content: start;
    flex-flow: column;
    align-items: flex-start;
  }
  .AboutNsd .score .block_num h2 {
    font-size: 10vw;
  }
  .AboutNsd .score .block_num h3 {
    font-size: 3vw;
  }
  .AboutNsd .score .block_num {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .AboutNsd .score .score_num {
    padding-top: 5vw;
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-self: center;
  }
}