.BriefInf {
  position: relative;
  display: flex;
  font-family: Rubik;
  color: white;
  font-size: 1vw;
  margin-left: 5.8vw;
  align-items: center;
  gap: 3vw;
  color: #1e444b;
  margin-bottom: 2vw;
}

.item {
  display: flex;
  gap: 0.5vw;
}

@media (max-width: 450px) {
  .BriefInf {
    display: flex;
    flex-direction: column;
    font-size: 3vw;
    align-items: start;
    gap: 3vw;
    color: #1e444b;
    margin: 2vw 0;
    margin-left: 5.8vw;
  }
  .item {
    display: flex;
    gap: 2vw;
  }
  .item img {
    width: 3vw !important;
  }
}