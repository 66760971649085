.HeaderImg {
  margin-bottom: 2vw;
  position: relative;
  height: 50vw;
  width: 100vw;
}

.HeaderImg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.2vw;
  background-color: rgba(0, 0, 0, 0.3); /* Черный цвет с 50% прозрачностью */
  z-index: 0;
}

.HeaderImg .info_flex {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20vw;
}

.HeaderImg .main_rt {
  font-family: Rubik-B;
  color: #f4f4f4;
  font-size: 3vw;
}

.HeaderImg .type_rt {
  font-family: Rubik-Re;
  width: 10vw;
  color: #dfdfdf;
  text-align: center;
  border: #dfdfdf 1px solid;
  border-radius: 10vw;
  font-size: 1.5vw;
  margin-top: 1vw;
  margin-bottom: 14vw;
}

.HeaderImg .foot_rt {
  display: flex;
  gap: 3vw;
  font-family: Rubik;
  color: white;
  font-size: 1.5vw;
}

.foot_rt .item {
  display: flex;
  gap: 1vw;
}

@media (max-width: 450px) {
  .HeaderImg {
    margin-bottom: 5vw;
    height: auto;
    width: 100vw;
  }

  .HeaderImg .info_flex {
    margin-top: 20vh;
    margin-bottom: 5vh;
  }

  .HeaderImg .main_rt {
    font-family: Rubik-B;
    color: #f4f4f4;
    font-size: 5.5vw;
    padding: 0 5vw;
  }

  .HeaderImg .type_rt {
    width: 20vw;
    font-size: 4vw;
    margin-top: 5vw;
    margin-bottom: 20vw;
  }

  .HeaderImg .foot_rt {
    flex-direction: column;
    align-items: center;
    gap: 3vw;
    font-family: Rubik;
    color: white;
    font-size: 3.8vw;
  }
  
  .foot_rt .item {
    display: flex;
    gap: 2vw;
  }

  .foot_rt .item img {
    height: 3vw !important;
  }
}