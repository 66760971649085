.Footer {
  display: flex;
  background-color: #1e444b;
  margin-top: auto;
}

.Footer p {
  font-size: 1.3vw;
}

.Footer a {
  color: #ffffff;
}

.footer_item {
  padding: 3vw 5vw;
}

.foot_t {
  color: #b0b0b0;
}

.foot_t2 {
  color: #ffffff;
  line-height: 1.5;
}

.Footer .social {
  display: flex;
  gap: 1vw;
}

.Footer .soc {
  width: 2vw;
}

@media (max-width: 450px) {
  .Footer {
    padding: 5vw 0;
    flex-flow: column;
  }
  .Footer p {
    font-size: 3vw;
  }
  .Footer .footer_item .social img {
    width: 5vw;
  }
  .Footer .footer_item .social {
    padding-top: 1vw;
    gap: 2vw;
  }
}